<template>
    <div class="container-fluid mt-5 pt-5">
        <div class="page-breadcrumb">
            <div class="col-12">
                <div class="row">
                    <div class="col-7 align-self-center pl-4">
                        <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">config. metragem</h3>
                        <div class="d-flex align-items-center">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb m-0 p-0">
                                    <li class="breadcrumb-item"><a href="?app=dashboard.php">dashboard / imóveis / config. metragem</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="col-5 align-self-center text-right">
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid qsuite-c-fluid-0">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-6">
                        <div class="card">
                            <div class="card-body pt-4">
                                <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label>Suíte</label>
                                                    <input type="number" v-model="name" class="form-control" :class="{'is-invalid': errors.has('name')}" name="name" v-validate="'required'">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-actions">
                                        <div class="text-right">
                                            <button type="submit" class="btn btn-info">Add</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-6">
                        <div class="col-12">
                            <div class="form-group d-flex">
                                <input type="search" v-model="search" class="form-control campo-procurar" placeholder="Procurar">
                                <button type="button" @click="goSearch" class="btn btn-info">Buscar</button>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body pt-4">
                                <form class="needs-validation" novalidate @submit.prevent="update">
                                    <div class="form-body">
                                        <table class="table no-wrap v-middle mb-0">
                                            <thead>
                                                <tr class="border-0">
                                                    <th class="border-0 text-muted pt-0 px-2 text-center"><span>Cadastrados</span></th>
                                                    <th class="border-0 font-14 font-weight-medium text-muted"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in cond" :key="item.id">
                                                    <td class="font-weight-medium text-dark border-top-0 px-2">
                                                        <input type="text" class="form-control" placeholder="" v-model="cond[index].name" >
                                                    </td>
                                                    <td class="border-top-0 text-muted px-0 py-0 font-14">
                                                        <select class="custom-select mr-sm-2" v-model="cond[index].is_active" >
                                                            <option :value="1">Ativa</option>
                                                            <option :value="0">Inativa</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="form-actions">
                                        <div class="text-right">
                                            <button type="submit" class="btn btn-info">Atualizar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>
    import EnterpriseConfigService from "@/services/resources/EnterpriseConfigService";
    const service = EnterpriseConfigService.build();
    
    export default {
      data() {
        return {
          name: null,
          cond: [],
          search: ''
        };
      },
      methods: {
        goSearch(){
            if(this.search.length == 0)
                this.fetchCond();
    
            this.cond = this.cond.filter(elem => {
                return elem.name.toLowerCase().includes(this.search.toLowerCase());
            });
        },
        save(){
          this.$validator.validateAll().then((result) => {
            if (result) {
    
            let data = {
              name: this.name,
              type: 'SUITE'
            }
            
              service
              .create(data)
                .then(resp => {
                    this.$bvToast.toast('Sua configuração foi criada com sucesso!', {
                      title: 'Configuração criada',
                      autoHideDelay: 5000,
                      type: 'success'
                    })
                    this.name = '';
                    this.$validator.reset();
                    this.fetchCond();
                })
                .catch(err => {
                  console.log(err)
                })
              }
          });
        },
        checkField(){
            return this.cond.filter(elem => {
                return elem.name.length == 0;
            }).length;
        },
        update(){
    
            if(this.checkField()){
                this.$bvToast.toast('Algum campo está inválido, por favor, verifique.', {
                    title: 'Campo inválido',
                    autoHideDelay: 5000,
                    type: 'danger'
                })
                return;
            }
    
            let data = {
              id: 0,
              config: this.cond
            }
        
            service
            .update(data)
            .then(resp => {
                this.$bvToast.toast('Sua configuração foi atualizada com sucesso!', {
                    title: 'Configuração atualizada',
                    autoHideDelay: 5000,
                    type: 'success'
                })
            })
            .catch(err => {
                console.log(err)
            })
        },
        fetchCond(){
            let data = {
                type: 'SUITE'
            }
    
            service
              .search(data)
                .then(resp => {
                  this.cond = resp;
                })
                .catch(err => {
                  console.log(err)
                })
        }
    
      },
      mounted() {
          this.fetchCond();
      },
    };
    </script>
    <style scoped lang="scss">
    .bg{
      background: #fff;
      padding: 10px;
      border: 1px #ddd solid;
      border-radius: 5px;
    }
    </style>