<template>
<div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" title="tipologia" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="card card-bg-trans">
                <div class="card-body pt-4 px-0">
                    <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                        <div class="form-body">
                            <div class="row">
                                 <div class="col-12 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Código da tipologia</label>
                                        <input type="text" id="inputSuccess1" class="form-control" placeholder="3234" v-model="tipology.code" name="code" v-validate="'required'" :class="{'is-invalid': errors.has('code')}">
                                    </div>
                                </div>
                                      
                                <div class="col-12 col-md-8 col-lg-8">
                                    <div class="form-group">
                                        <label>Descrição da tipologia (uso interno)</label>
                                        <input type="text" id="inputSuccess1" class="form-control" placeholder="tipologia 1" v-model="tipology.identification_name" name="name" v-validate="'required'" :class="{'is-invalid': errors.has('name')}">
                                    </div>
                                </div>
                                <div class="col-12 col-md-8 col-lg-8">
                                    <div class="form-group">
                                        <label>Nome da tipologia</label>
                                        <input type="text" id="inputSuccess1" class="form-control" placeholder="Ex.: 71,24m²" v-model="tipology.name" name="name" v-validate="'required'" :class="{'is-invalid': errors.has('name')}">
                                    </div>
                                </div>
                                <div class="col-12 col-md-8 col-lg-8">
                                    <div class="form-group">
                                        <label>Descrição</label>
                                        <input type="text" id="inputSuccess1" class="form-control" placeholder="Apartamento com 01 suíte, 1 quarto e banho social." v-model="tipology.description" name="description" v-validate="'required'" :class="{'is-invalid': errors.has('description')}">
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Cor</label>
                                        <input type="color" v-model="tipology.color" class="form-control" :class="{'is-invalid': errors.has('color')}" name="color" v-validate="'required'">
                                    </div>
                                </div>
                                <div class="col-12 pt-3"></div>
                                <div class="col-12 pb-2">
                                    <label>Imagem da tipologia</label>
                                    <vue-upload-multiple-image
                                        @upload-success="uploadImageSuccess"
                                        @before-remove="beforeRemove"
                                        @edit-image="editImage"
                                        :data-images="tipology.image"
                                        idUpload="myIdUpload"
                                        editUpload="myIdEdit"
                                        dragText="Clique ou arraste uma image aqui"
                                        browseText="Procurar imagem"
                                        :multiple="false"
                                        :showPrimary="false"
                                        :showEdit="false">
                                    </vue-upload-multiple-image>
                                </div>                            
        
                           
                                <div class="col-12 pt-4 mt-5">
                                    <div class="form-group">
                                        <label  class="mt-5 mb-5">Arquivos da tipologia</label>

                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Upload</span>
                                            </div>                                                 
                                            <div class="custom-file">
                                                <input required type="file" multiple class="custom-file-input" @change="defineFile" id="inputGroupFile01">
                                                <label class="custom-file-label" for="inputGroupFile01"></label>
                                            </div>        
                                        </div>
                                    </div>                                          
                                </div>
                                <div class="d-flex flex-wrap justify-content-center mx-auto" >
                                    <div class="card mt-5" v-if="tipology_files_added.length >= 1"  v-for="(file, id) in tipology_files_added">
                                        <img style="max-height: 251px; width: 251px; object-fit: contain;" :src="'https://placehold.co/400x400/EEE/31343C?font=lato&text='+fileExtention(file.name)" :id="'fileadded'+id">
                                        <b class="mt-2 mb-2" style="font-size: 12px; word-break: break-all;">{{file.name}}</b>
                                        <div class="card-body">
                                            <div class="col-lg-12 col-12 px-2 d-flex align-itens-center" style="align-items:center !important">
                                            <input
                                                type="text"
                                                class="form-control col-9 mt-2"
                                                v-model="file.description"
                                            />
                                            <b
                                                class="col-2 d-flex justify-content-center align-items-center" style="cursor:pointer;height: 40px;background: #dfdfdf;"
                                                @click="removeImageFromList(id)"
                                            >
                                                <i class="fas fa-times text-danger"></i>
                                            </b>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="card mt-5" v-if="tipology.tipology_files.length >= 1"  v-for="(file, id) in tipology.tipology_files">
                                        <img v-if="file.id == null" style="max-height: 251px; width: 251px; object-fit: contain;"  src="" :id="'file'+id">
                                        <img v-else style="max-height: 251px; width: 251px; object-fit: contain;" :src="'https://placehold.co/400x400/EEE/31343C?font=lato&text='+fileExtention(file.name)" :id="'file'+id">
                                        <b class="mt-2 mb-2" style="font-size: 12px; word-break: break-all;">{{file.name}}</b>
                                        <div class="card-body">
                                            <div class="col-lg-12 col-12 px-2 d-flex align-itens-center" style="align-items:center !important">
                                            <input
                                                type="text"
                                                class="form-control col-9 mt-2"
                                                v-model="file.description"
                                            />
                                            <b
                                                class="col-2 d-flex justify-content-center align-items-center" style="cursor:pointer;height: 40px;background: #dfdfdf;"
                                                @click="removeImageFromBd(id)"
                                            >
                                                <i v-if="file.deleted != 1" class="fas fa-times text-danger"></i>
                                                <i v-else class="fas fa-undo text-primary"></i>
                                            </b>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row d-flex justify-content-start mt-5">
                                    <div class="card pb-2 mt-5" v-if="tipology.tipology_files.length >= 1"  v-for="(file, id) in tipology.tipology_files">
                                        <div class="col-lg-5">
                                            <img style="max-height: 100%; width: 100%; object-fit: contain;"  src="" :id="'file'+id">
                                        </div>
                                        <div class="col-lg-5 px-2 d-flex align-itens-center" style="align-itens:center !important">
                                            <input
                                                type="text"
                                                class="form-control col-9 mt-2"
                                                v-model="file.description"
                                            />
                                            <b
                                                class="col-2 d-flex justify-content-center align-items-center" style="cursor:pointer;height: 40px;background: #dfdfdf;"
                                                @click="remove(id)"
                                            >
                                                <i class="fas fa-times text-danger"></i>
                                            </b>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="form-actions">
                            <div class="text-right">
                                <button type="reset" class="btn btn-dark">Cancelar</button>
                                <button type="submit" class="btn btn-info ml-2">{{ id ? 'Salvar' : 'Cadastrar' }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import TipologyService from "@/services/resources/TipologyService";
import VueUploadMultipleImage from "vue-upload-multiple-image";

const service = TipologyService.build();

export default {
  components:{
       VueUploadMultipleImage
  },
  data() {
    return {
      tipology: {
          code: null, 
          name: null,
          identification_name:"",
          description: null,
          color: null,
          image: [],
          tipology_files: []        
      },
        tipology_files_added: [],
      search: '',
      id: null,
      pages:[
        {
            name: 'dashboard',
            to: 'Dashboard'
        },
        {
            name: 'imóveis',
            to: 'Enterprise'
        }
      ],
      menu:[
        {
            name: 'Imóveis',
            to: 'Enterprise'
        }
      ]
    };
  },
  methods: {
    uploadImageSuccess(formData, index, fileList) {
        this.tipology.image = fileList;
    },
    beforeRemove(index, done, fileList) {
        var r = confirm("Remover imagem?");
        if (r == true) {
            this.tipology.image.splice(index, 1);
            done();
        }
    },
    editImage(formData, index, fileList) {
        this.tipology.image = fileList;
    },
    save(){

        this.tipology_files_added.forEach(function(file) {
            this.tipology.tipology_files[this.tipology.tipology_files.length] = file
        }.bind(this))

        this.tipology_files_added.length = 0

      this.$validator.validateAll().then((result) => {
        if (result) {

            let tipology = Object.assign({}, this.tipology)
            tipology.image = tipology.image[0];

            if(this.id){
                tipology.id = this.id;

                service
                .update(tipology)
                .then(resp => {
                    this.$bvToast.toast('Sua tipologia foi atualizada com sucesso!', {
                        title: 'Tipologia atualizada',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                    this.fetchTipology()
                })
                .catch(err => {
                })
            }else{
                service
                .create(tipology)
                .then(resp => {
                    this.$bvToast.toast('Sua tipologia foi criada com sucesso!', {
                        title: 'Tipologia criada',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
            

                    this.tipology.tipology_files = []
                    
                })
                .catch(err => {
                })
            }
          }
      });
    },
    fetchTipology(){

        if(!this.id){
            return;
        }

        let data = {
            id: this.id
        }

        service
          .read(data)
            .then(resp => {
              this.tipology.code = resp.code;
              this.tipology.name = resp.name;
              this.tipology.description = resp.description;
              this.tipology.color = resp.color;
              this.tipology.identification_name=resp.identification_name
              this.tipology.image = [{path: resp.image}];
              this.tipology.tipology_files = resp.files
            })
            .catch(err => {
              console.log(err)
            })
    },
    removeImageFromBd(id) { 
        if (this.tipology.tipology_files[id].deleted != 1) {
            this.tipology.tipology_files[id].deleted = 1 
        }  else {
            this.tipology.tipology_files[id].deleted = 0
        }         
    },
    removeImageFromList(id) {
        this.tipology_files_added.splice(id, 1);
        this.$forceUpdate()
    },
    reshowFiles() {
        this.tipology.tipology_files.forEach((file, id) => {
            var reader = new FileReader();
            reader.onload = function (eve2) {               
                $('#file'+id).attr('src', eve2.target.result);
            }          
            reader.readAsDataURL(file.file);
        })
    },
    fileExtention(file_name) {
        return file_name.split(".").pop().toUpperCase()
    },
    defineFile(eve) {   
        for (let id = 0; id <  eve.target.files.length; id++) {  
            var reader = new FileReader();        
            let type_image = eve.target.files[id].name.split(".").pop();
            let name_image =  eve.target.files[id].name
            reader.onload = function (eve2) { 
                this.tipology_files_added[this.tipology_files_added.length] = { file: eve2.target.result, deleted: 0,  name: eve.target.files[id].name, description: "", date_hour_delete: ""}     
                this.$forceUpdate()       
                $('#fileadded'+id).attr('src', "https://placehold.co/400x400/EEE/31343C?font=lato&text="+type_image.toUpperCase());
            }.bind(this)   
            this.$forceUpdate()      
            reader.readAsDataURL(eve.target.files[id]);                      
        }     
       
        // this.tipology.tipology_files.forEach((file, id) => {
        //     var reader = new FileReader();
        //     console.log(file);
        //     let type_image = file["file"+id].name.split(".").pop();
        //     let name_image = file["file"+id].name
        //     reader.onload = function (eve2) {               
        //         $('#file'+id).attr('src', "https://placehold.co/400x400/EEE/31343C?font=lato&text="+name_image);
        //     }          
        //     reader.readAsDataURL(file["file"+id]);
        // })
      //  this.quantity_files = this.tipology.tipology_files.length
      // this.file_unit.file.name = eve.target.files[0].name;


    //   for (let id = 0; id <  eve.target.files.length; id++) {       
    //         this.tipology.tipology_files[this.tipology.tipology_files.length] = 
    //         { ["file"+id]: eve.target.files[id], name: eve.target.files[id].name, description: "", date_hour_delete: ""}
    //     }     
     
    //     this.$forceUpdate()
   
    //     let files = []
    //     this.tipology.tipology_files.forEach((file, id) => {
    //         var reader = new FileReader();
    //         console.log(file);
           
    //         let type_image = file["file"+id].name.split(".").pop();
    //         let name_image = file["file"+id].name
    //         reader.onload = function (eve2) {       
    //             files[files.length] = {["file"+id]: eve2.target.result, name: file.name, description: file.description, date_hour_delete: file.date_hour_delete}   
    //             $('#file'+id).attr('src', "https://placehold.co/400x400/EEE/31343C?font=lato&text="+name_image);
    //         }   
              
    //         reader.readAsDataURL(file["file"+id]);
    //     })

    //     this.tipology.tipology_files = files     



    }
  }, 
  mounted() {
      this.id = this.$route.params.id;
      this.fetchTipology();
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>