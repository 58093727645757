<template>
<div>
  <b-container style="margin-top: 80px;">
    <BasePageBreadCrumb :pages="pages" :title="id ? 'editar empreendimento' : 'novo empreendimento'" :menu="menu" />
    <div class="container-fluid qsuite-c-fluid-0">
      <b-col cols="7">
        <form @submit.prevent="save" novalidate>
          <!-- <input type="text" placeholder="Nome do empreendimento" class="form-control" v-model="name" name="name" v-validate="'required'" />
          <b-form-invalid-feedback :state="!errors.has('name')">
          Por favor, insira o nome.
          </b-form-invalid-feedback> -->
        </form>
      </b-col>
       <div class="container-fluid qsuite-c-fluid-0">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans border-0">
                        <div class="card-body pt-4 px-0">
                            <div class="tab-content">
                              <b-tabs active-nav-item-class="active-tab-qsuite">
                                <form class="px-2 needs-validation" @submit.prevent="save">
                                    <b-tab class="tab-pane show active" id="dadosdoimovel">
                                        <template #title>
                                        <div class="d-flex">
                                            <i class="far fa-building font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Dados do imóvel <b-badge variant="danger" v-show="basic_error">1</b-badge></span>
                                        </div>
                                        </template>
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col-12 text-right mt-2" v-if="id">
                                                        <span class="badge badge-success mr-2" ><b>Data cadastro</b> {{ enterprise.created_at | date }}</span>
                                                        <span class="badge badge-secondary"><b>Última atualização</b> {{ enterprise.updated_at | date }}</span>
                                                    </div>
                                                    <div class="col-12 pt-2"></div>
                                                    <div class="col-12 col-md-10 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Cód. referência</label>
                                                            <input type="tel" class="form-control" placeholder="#8989"
                                                            v-model="enterprise.cod" >
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-10 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Tipo de imóvel </label>
                                                            <b-form-select class="custom-select mr-sm-2" :options="types" v-model="enterprise.type_id" name="type" v-validate="'required'" :class="{'is-invalid': errors.has('type')}">
                                                            </b-form-select>
                                                        </div>
                                                    </div>
                                                  <div class="col-12 col-md-10 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Finalidade</label>
                                                            <b-form-select class="custom-select mr-sm-2" :options="finalidade" v-model="enterprise.finalidade_id" name="type" :class="{'is-invalid': errors.has('type')}">
                                                            </b-form-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Status </label>
                                                            <b-form-select class="custom-select mr-sm-2" :options="status" v-model="enterprise.status_id" name="status" v-validate="'required'" :class="{'is-invalid': errors.has('status')}">
                                                            </b-form-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Nome do imóvel </label>
                                                            <input type="text" class="form-control" v-model="enterprise.name" name="name" v-validate="'required'" :class="{'is-invalid': errors.has('name')}">
                                                        </div>
                                                    </div>                                                    
                                                                                                
                                                </div>

                                            </div>
                                    </b-tab>
                              
                                    <b-tab class="tab-pane" id="caracteristicas">
                                    <template #title>
                                        <div class="d-flex">
                                            <i class="far fa-list-alt font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Características <b-badge variant="danger" v-show="caracteristics_error">1</b-badge></span>
                                        </div>
                                    </template>
                                            <div class="form-body">
                                                <div class="row">
                                                   <div class="col-12 mb-3">
                                                        <div class="form-group">
                                                            <label>Legenda (Frase de impacto) </label>
                                                              <input class="form-control" v-model="enterprise.effect_phrase" />                                                             
                                                        </div>
                                                        <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Frase de impacto que aparece no imóvel</small>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Sobre o imóvel </label>
                                                              <textarea class="form-control" v-model="enterprise.description" name="editordata"></textarea>                                                             
                                                        </div>
                                                        <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Descrição para anúncios, sites e mais...</small>
                                                    </div>
                                                    <div class="col-12 pt-4 pb-2">    
                                                        <BaseDifferencesBox :key="difference" v-model="enterprise.configs" />
                                                        <p data-bs-modal="" class="mt-3 btn btn-info" data-bs-toggle="modal" data-bs-target="#differences"><i class="fa fa-plus"></i> Adicionar</p>
                                                        
                                                        <!-- Modal -->
                                                        <div class="modal fade" id="differences" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog modal-xl">
                                                                <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title" id="exampleModalLabel">Adicione uma configuração</h5>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <ConfigDifferences />
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click='recarregarConfiguracoes("DIFFERENCES");atualizaDiferencas()'>Sair</button>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="col-12 pt-4 pb-2"> 
                                                        <BaseCondBox v-model="enterprise.configs" :key="conds" /> 
                                                        <p data-bs-modal="" class="mt-3 btn btn-info" data-bs-toggle="modal" data-bs-target="#cond"><i class="fa fa-plus"></i> Adicionar</p>
                                                        
                                                        <!-- Modal -->
                                                        <div class="modal fade" id="cond" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog modal-xl">
                                                                <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title" id="exampleModalLabel">Adicione uma configuração</h5>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <ConfigCond />
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click='atualizaCond()'>Sair</button>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                       <div class="col-12 pt-4 pb-2"> 
                                                        <BaseQuartoBox v-model="enterprise.configs" :key="quartos" /> 
                                                           <p data-bs-modal="" class="mt-3 btn btn-info" data-bs-toggle="modal" data-bs-target="#quarto"><i class="fa fa-plus"></i> Adicionar</p>
                                                        
                                                        <!-- Modal -->
                                                        <div class="modal fade" id="quarto" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog modal-xl">
                                                                <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title" id="exampleModalLabel">Adicione uma configuração</h5>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <ConfigQuarto />
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click='atualizaQuarto()'>Sair</button>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                       <div class="col-12 pt-4 pb-2"> 
                                                        <BaseSuiteBox v-model="enterprise.configs" :key="suites" /> 
                                                           <p data-bs-modal="" class="mt-3 btn btn-info" data-bs-toggle="modal" data-bs-target="#suite"><i class="fa fa-plus"></i> Adicionar</p>
                                                        
                                                        <!-- Modal -->
                                                        <div class="modal fade" id="suite" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog modal-xl">
                                                                <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title" id="exampleModalLabel">Adicione uma configuração</h5>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <ConfigSuite />
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click='atualizaSuite()'>Sair</button>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-4 pb-2"> 
                                                        <BaseMetragemBox v-model="enterprise.configs" :key="metragens" /> 
                                                           <p data-bs-modal="" class="mt-3 btn btn-info" data-bs-toggle="modal" data-bs-target="#metragem"><i class="fa fa-plus"></i> Adicionar</p>
                                                        
                                                        <!-- Modal -->
                                                        <div class="modal fade" id="metragem" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog modal-xl">
                                                                <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title" id="exampleModalLabel">Adicione uma configuração</h5>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <ConfigMetragem />
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click='atualizaMetragem()'>Sair</button>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-4 pb-2"> 
                                                        <BaseFinalidadeBox v-model="enterprise.configs" :key="finalidades" /> 
                                                           <p data-bs-modal="" class="mt-3 btn btn-info" data-bs-toggle="modal" data-bs-target="#finalidade"><i class="fa fa-plus"></i> Adicionar</p>
                                                        
                                                        <!-- Modal -->
                                                        <div class="modal fade" id="finalidade" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog modal-xl">
                                                                <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title" id="exampleModalLabel">Adicione uma configuração</h5>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <ConfigFinalidade />
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click='atualizaFinalidade()'>Sair</button>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </b-tab>
                                    <b-tab class="tab-pane" id="informacoestecnicas">
                                    <template #title>
                                        <div class="d-flex">
                                            <i class="far fa-id-card font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Informações técnicas <b-badge variant="danger" v-show="technical_error">1</b-badge></span>
                                        </div>
                                        </template>
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Área do terreno (m²)</label>
                                                            <input type="text" class="form-control" placeholder="3.744,12 m²" v-mask="['###,## m²', '#.###,## m²', '##.###,## m²']" v-model="enterprise.area">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>N˚ torre</label>
                                                            <b-form-select v-model="enterprise.tower" :options="numbers"></b-form-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>N˚ subsolos</label>
                                                            <b-form-select v-model="enterprise.undergrounds" :options="numbers"></b-form-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Térreo</label>
                                                            <select class="custom-select mr-sm-2" v-model="enterprise.ground_floor">
                                                                <option :value="null">Selecione</option>
                                                                <option :value="true">Sim</option>
                                                                <option :value="false">Não</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Mezanino</label>
                                                            <select class="custom-select mr-sm-2" v-model="enterprise.mezzanine">
                                                                <option :value="null">Selecione</option>
                                                                <option :value="true">Sim</option>
                                                                <option :value="false">Não</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-4 pb-2">
                                                        <br>
                                                        <h4 class="card-title">Pavimentos</h4>
                                                    </div>
                                                    <div class="row" v-for="(item, index) in enterprise.grounds" :key="index" >
                                                        <div class="col-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Descrição</label>
                                                                <input type="text" class="form-control" placeholder="ex.: Duplex" v-model="enterprise.grounds[index].name" >
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3 col-lg-3">
                                                            <div class="form-group">
                                                                <label>N˚ de andar</label>
                                                                <b-form-select class="custom-select mr-sm-2" :options="floors" v-model="enterprise.grounds[index].floors">
                                                                </b-form-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3 col-lg-3">
                                                            <div class="form-group">
                                                                <label>N˚ de apartamento por andar</label>
                                                                <b-form-select class="custom-select mr-sm-2" :options="floors" v-model="enterprise.grounds[index].apt_numbers">
                                                                </b-form-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-1">
                                                            <button class="btn mt-4" v-show="index > 0 && (id == null || item.unities.length == 0)" @click="remove(index)"><i class="fas fa-times text-danger"></i></button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-4 align-self-start">
                                                        <div class="form-group">
                                                            <button type="button" class="btn btn-info" @click="addGround">Adicionar mais</button>
                                                        </div>
                                                    </div>

                                        
                                                    
                                                </div>

                                                <div :class="{'row justify-content-center': item.unities.length > 1}" v-for="(item, index) in enterprise.grounds.slice().reverse()" :key="index">
                                                    <div class="form-body" >
                                                        <div class="d-flex mx-auto justify-content-center" v-for="(unity, index) in item.unities.slice().reverse()" :key="index">
                                                            <div style="background-color: #02b475; width:70px; font-size:13px" class="text-white text-center px-2 m-1" :style="{'width': item.unities.length == 1 ? '100%' : '70px'}" v-for="(elem, index) in unity" :key="index">{{ elem.number }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-body" v-if="enterprise.mezzanine" v-show="id">
                                                    <div style="background-color: #02b475; width:100%; font-size:13px" class="text-white text-center px-2 m-1">Mezanino</div>
                                                </div>
                                                <div class="form-body pb-4" v-if="enterprise.ground_floor" v-show="id">
                                                    <div style="background-color: #02b475; width:100%; font-size:13px" class="text-white text-center px-2 m-1">Térreo</div>
                                                </div>

                                            </div>
                                    </b-tab>
                                    <b-tab class="tab-pane show active" id="dadosdoimovel">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-images font-20 align-middle mr-2"></i><span class="d-none d-lg-block">Mídias<b-badge variant="danger" v-show="basic_error">1</b-badge></span>
                                            </div>
                                        </template>

                                        
                                        <div class="col-6 mt-4">
                                            <div class="form-group"><label>Link do vídeo do YouTube</label>
                                                <input type="text" class="form-control" v-model="enterprise.link_youtube" name="link_youtube"  :class="{'is-invalid': errors.has('link_youtube')}">
                                            </div>
                                        </div> 
                                        
                                        <div class="col-12 col-lg-12">
                                            <div class="d-flex flex-wrap  justify-content-around">
                                                            <div class="col-lg-6 col-md-3 pt-3">                                  
                                                                <label>Logo do imóvel </label>
                                                                <vue-upload-multiple-image                                                            
                                                                        @upload-success="uploadImageSuccess"
                                                                        @before-remove="beforeRemove"
                                                                        @edit-image="editImage"
                                                                        :data-images="enterprise.image ?? [{path: null}]"
                                                                        idUpload="myIdUpload"
                                                                        editUpload="myIdEdit"
                                                                        dragText="Clique ou arraste uma image aqui"
                                                                        browseText="Procurar imagem"
                                                                        :showPrimary="false"
                                                                        :showEdit="false"
                                                                        :multiple="false"
                                                                        :maxImage="1">
                                                                    </vue-upload-multiple-image>
                                                            </div>

                                                            <div class="col-lg-6 col-md-3 pt-3">                                    <label>Imagem do imóvel </label>
                                                                    <vue-upload-multiple-image
                                                                        @upload-success="uploadImageSuccessPrincipal2"
                                                                        @before-remove="beforeRemove2"
                                                                        @edit-image="editImage2"
                                                                        :data-images="enterprise.image_principal ?? [{path: null}]"
                                                                        idUpload="myIdUpload2"
                                                                        editUpload="myIdEdit2"
                                                                        dragText="Clique ou arraste uma image aqui"
                                                                        browseText="Procurar imagem"
                                                                        :showPrimary="false"
                                                                        :showEdit="false"
                                                                        :multiple="false"
                                                                        :maxImage="1">
                                                                    </vue-upload-multiple-image>
                                                            </div>                                                    
                                                    </div>
                                        </div>
                                          
                                    </b-tab>
                                    <b-tab class="tab-pane" id="configuracoes">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-sun font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Configurações</span>
                                            </div>
                                        </template>
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Status do imóvel </label>
                                                            <select class="custom-select mr-sm-2" v-model="enterprise.is_active" name="status" v-validate="'required'" :class="{'is-invalid': errors.has('status')}">
                                                                <option :value="true">Ativo</option>
                                                                <option :value="false">Inativo</option>
                                                            </select>
                                                        </div>
                                                        <small id="name13" class="badge badge-default badge-danger form-text text-white">Desativar o imóvel na plataforma</small>
                                                    </div>
                                                     <div class="col-12 col-md-10 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Mostrar apenas na landing page do corretor?</label>
                                                            <select class="custom-select mr-sm-2" v-model="enterprise.showcan" name="type" v-validate="'required'" :class="{'is-invalid': errors.has('type')}">
                                                                <option value="não" selected>não</option>
                                                                <option value="sim">sim</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Unidades do UAU </label>
                                                            <b-form-select class="custom-select mr-sm-2" :options="uauEnterprises" v-model="enterprise.unity">
                                                            </b-form-select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                    </b-tab>
                                    <div class="form-actions">
                                        <div class="text-right">
                                            <button type="reset" class="btn btn-dark">Cancelar</button>
                                            <button type="submit" class="btn btn-info ml-2">{{ id ? 'Salvar' : 'Criar' }}</button>
                                        </div>
                                    </div>
                                </form>
                              </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </b-container>

</div>
</template>
<script>
import EnterpriseService from "@/services/resources/EnterpriseService";
import UauEnterpriseService from "@/services/resources/Uau/EnterpriseService";
import EnterpriseConfigService from "@/services/resources/EnterpriseConfigService";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import moment from 'moment';
import ConfigDifferences from './ConfigDifferences.vue'
import ConfigCond from './ConfigCond.vue'
import ConfigTiplogy from './ConfigTipology/New.vue'
import ConfigMetragem from './ConfigMetragem.vue'
import ConfigFinalidade from './ConfigFinalidade.vue'
import ConfigQuarto from './ConfigQuarto.vue'
import ConfigStatus from './ConfigStatus.vue'
import ConfigSuite from './ConfigSuite.vue'
import ConfigType from './ConfigType.vue'

const serviceUau = UauEnterpriseService.build();
const service = EnterpriseService.build();
const serviceConfig = EnterpriseConfigService.build();

export default {
  components:{
    VueUploadMultipleImage,
    ConfigDifferences,
    ConfigCond,
    ConfigTiplogy,
    ConfigMetragem,
    ConfigFinalidade,
    ConfigQuarto,
    ConfigStatus,
    ConfigSuite,
    ConfigType
  },
  data() {
    return {
      enterprise:{
        name: null,
        cod: null,
        finalidade: "",
        link_youtube: "",
        effect_phrase: "",
        type_id: null,
        status_id: null,
        description: null,
        configs: [],
        area: null,
        tower: null,
        undergrounds: null,
        ground_floor: null,
        mezzanine: null,
        is_active: true,
        unity: null,
        grounds: [
          {
              name: null,
              floors: null,
              apt_numbers: null,
              unities: []
          }
        ],
        image: [],
        image_principal: []
      },
      apts: [],
      floor: null,
      apt: null,
      index: null,
      lastGround: null,
      types:[
        {
          value: null, text: 'Selecione'
        }
      ],
       finalidades:[
        {
          value: null, text: 'Selecione'
        }
      ],
      status: [
        {
          value: null, text: 'Selecione'
        }
      ],
      floors: [
        {
            value: null, text: 'Selecione'
        }
      ],
      apt_floors: [
        {
            value: null, text: 'Selecione'
        }
      ],
      numbers: [
        {
            value: null, text: 'Selecione'
        }
      ],
      unities: [],
      cond: [],
      finalidade: [],
      metragem: [],
      quarto: [],
      suite: [],
      differences: [],
      searchDifferences: '',
      searchCond: '',
      caracteristics_error: false,
      basic_error: false,
      technical_error: false,
      id: null,
      uauEnterprises: [],
      difference: "differences0",
      tipos: "tipo0",
      conds: "conds0",
      finalidades: "finalidades0",
      metragens: "metragens0",
      quartos: "quartos0",
      suites: "suites0",
      status: "status",
      tipos: "tipos0",
      pages:[
          {
              name: 'dashboard',
              to: 'Dashboard'
          },
          {
              name: 'imóveis',
              to: 'Enterprise'
          },
          {
              name: 'novo',
              to: 'EnterpriseNew'
          }
      ],
      menu:[
        {
            name: 'Novo',
            to: 'EnterpriseNew'
        },
        {
            name: 'Imóveis',
            to: 'Enterprise'
        }
      ]   
    };
  },
  filters:{
      date(date){
          return moment(date).format('DD/MM/YYYY H:MM');
      }
  },

  methods: {
    uploadImageSuccess(formData, index, fileList, option = null) {
        this.enterprise.image = fileList;
      
       
    },
    atualizaDiferencas() {
        this.difference = this.difference.split("difference")[0] + parseInt(this.difference.split("difference")[1]) + 1
    },
      atualizaCond() {
        this.conds = this.conds.split("conds")[0] + parseInt(this.conds.split("conds")[1]) + 1
    },
      atualizaTipologia() {
        this.types = this.types.split("types")[0] + parseInt(this.types.split("types")[1]) + 1
    },
      atualizaQuarto() {
        this.quartos = this.quartos.split("quartos")[0] + parseInt(this.quartos.split("quartos")[1]) + 1
    },
      atualizaStatus() {
        this.status = this.status.split("status")[0] + parseInt(this.satus.split("status")[1]) + 1
    },
     atualizaSuite() {
        this.suites = this.suites.split("suites")[0] + parseInt(this.suites.split("suites")[1]) + 1
    },
      atualizaMetragem() {
        this.metragens= this.metragens.split("metragens")[0] + parseInt(this.metragens.split("metragens")[1]) + 1
    },
      atualizaFinalidade() {
        this.finalidades = this.finalidades.split("finalidades")[0] + parseInt(this.finalidades.split("finalidades")[1]) + 1
    },
      atualizaTipos() {
        this.tipos = this.tipos.split("tipo")[0] + parseInt(this.tipos.split("tipo")[1]) + 1
    },
    uploadImageSuccessPrincipal(formData, index, fileList, option = null) {
        this.enterprise.image = fileList;
    },
    beforeRemove(index, done, fileList, option = null) {
        var r = confirm("Remover imagem?");
        if (r == true) {

                this.enterprise.image.splice(index, 1);
          
          
        }
    },
    editImage(formData, index, fileList, option = null) {

            this.enterprise.image = fileList;
      
    },
    uploadImageSuccessPrincipal2(formData, index, fileList, option = null) {
        this.enterprise.image_principal = fileList;
    },
    beforeRemove2(index, done, fileList, option = null) {
        var r = confirm("Remover imagem?");
        if (r == true) {

                this.enterprise.image_principal.splice(index, 1);
        

          
        }
    },
    editImage2(formData, index, fileList, option = null) {

            this.enterprise.image_principal = fileList;
    
    },
    addGround(){
        this.enterprise.grounds.push({
              name: null,
              floors: null,
              apt_numbers: null,
              unities: []
          });
    },
    remove(index){
        this.$delete(this.enterprise.grounds, index);
    },
    validateBasicTab(){

        if(!this.enterprise.name || !this.enterprise.type_id || !this.enterprise.status_id){
            this.basic_error = true;
            return false;
        }

        return true;
    },
    validateTechnicalTab(){
        if(!this.enterprise.grounds.length){  
            this.technical_error = true;
            return false;
        }

        for(let i = 0; i < this.enterprise.grounds.length; i++){
            if(!this.enterprise.grounds[i].name || !this.enterprise.grounds[i].floors || !this.enterprise.grounds[i].apt_numbers){
                this.technical_error = true;
                this.$bvToast.toast('Preencha as informações de cada andar corretamente', {
                    title: 'Erro',
                    autoHideDelay: 5000,
                    type: 'danger'
                });
                return false;
            }
        }

        return true;
    },
    // generateFloor(){
    //     let grounds = [];
    //     let lastGround = 1;
    //     for(let i = 0; i < this.enterprise.grounds.length; i++){
    //         for(let k = 1; k <= this.enterprise.grounds[i].floors; k++){
    //             grounds.push({
    //                 unities: []
    //             })
    //             grounds[i].unities.push(new Array(this.enterprise.grounds[i].apt_numbers).fill().map((e,j) => {
    //                 return {
    //                     number: this.getFloors(lastGround, j+1),
    //                     tipology_id: null,
    //                     floor: lastGround,
    //                     unity_number: j + 1
    //                 }
    //             }));
                
    //             lastGround++;
    //         }
    //     }
    //     console.log(grounds);
    //     return grounds;
    // },
    getFloors(floor, i){
        return `${floor}${String(i).padStart(2, '0')}`;
    },
    save(){
      this.basic_error = false;
      this.technical_error = false;
      this.caracteristics_error = false;

    //  this.$data.enterprise.description = $("#summernote").val()

     // this.$data.enterprise.description = this.

      this.$validator.validateAll().then((result) => {
        if (result & this.validateBasicTab() & this.validateTechnicalTab()) {
        
        let enterprise = Object.assign({}, this.enterprise);
        if(enterprise.image != null && enterprise.image.length)
            enterprise.image = enterprise.image[0];

         if(enterprise.image_principal != null && enterprise.image_principal.length) {
             enterprise.image_principal = enterprise.image_principal[0];
        }
            

        enterprise.configs = enterprise.configs.map(elem =>{
                if(typeof elem == 'object'){
                    return elem.config_id;
                }else{
                    return elem;
                }
        });

        if(this.id){
            enterprise.id = this.id;

            for(let i = 0; i < enterprise.grounds.length; i++){
                if(enterprise.grounds[i].unities.length == 0){
                    if(i != 0){
                        var lastGround = enterprise.grounds[i - 1].unities[enterprise.grounds[i - 1].unities.length - 1][0].floor + 1;
                    }else{
                        var lastGround = 1;
                    }
                    for(let k = 1; k <= enterprise.grounds[i].floors; k++){
                        enterprise.grounds[i].unities.push(new Array(enterprise.grounds[i].apt_numbers).fill().map((e,j) => {
                            return {
                                number: this.getFloors(lastGround, j+1),
                                tipology_id: null,
                                floor: lastGround,
                                unity_number: j + 1
                            }
                        }));
                        
                        lastGround++;
                    }
                }

                if(enterprise.grounds[i].unities.length < enterprise.grounds[i].floors){
                    let lastGround = enterprise.grounds[i].unities[enterprise.grounds[i].unities.length - 1][0].floor + 1;
                    let  floorIndex = enterprise.grounds[i].unities.length;
                    for(let k = floorIndex; k < enterprise.grounds[i].floors; k++){
                        enterprise.grounds[i].unities.push(new Array(enterprise.grounds[i].apt_numbers).fill().map((e,j) => {
                            return {
                                number: this.getFloors(lastGround, j+1),
                                tipology_id: null,
                                floor: lastGround,
                                unity_number: j + 1
                            }
                        }));
                        lastGround++;
                    }
                }

                if(enterprise.grounds[i].unities[0].length < enterprise.grounds[i].apt_numbers){

                    let floorIndex = enterprise.grounds[i].unities[0].length;

                    for(let j = 0; j < enterprise.grounds[i].unities.length; j++){
                        let number = enterprise.grounds[i].unities[j][enterprise.grounds[i].unities[j].length - 1].unity_number + 1;
                        for(let k = floorIndex; k < enterprise.grounds[i].apt_numbers; k++){
                            enterprise.grounds[i].unities[j].push({
                                number: this.getFloors(enterprise.grounds[i].unities[j][0].floor, number),
                                tipology_id: null,
                                floor:  enterprise.grounds[i].unities[j][0].floor,
                                unity_number: number
                            });

                            number++;
                        }
                    }
                }

                
            }

            enterprise.description = this.$data.enterprise.description

            service
            .update(enterprise)
            .then(resp => {
                this.$bvToast.toast('Seu empreendimento foi atualizado com sucesso!', {
                    title: 'Empreendimento atualizado',
                    autoHideDelay: 5000,
                    type: 'success'
                })
            })
            .catch(err => {
                this.$bvToast.toast('Houve um erro inesperado.', {
                    title: 'Empreendimento',
                    autoHideDelay: 5000,
                    type: 'danger'
                })
            })
        }else{

            let lastGround = 1;
            for(let i = 0; i < enterprise.grounds.length; i++){
                for(let k = 1; k <= enterprise.grounds[i].floors; k++){
                    enterprise.grounds[i].unities.push(new Array(enterprise.grounds[i].apt_numbers).fill().map((e,j) => {
                        return {
                            number: this.getFloors(lastGround, j+1),
                            tipology_id: null,
                            floor: lastGround,
                            unity_number: j + 1
                        }
                    }));
                    
                    lastGround++;
                }
            }

            service
            .create(enterprise)
            .then(resp => {
                this.$bvToast.toast('Seu empreendimento foi criado com sucesso!', {
                    title: 'Empreendimento criado',
                    autoHideDelay: 5000,
                    type: 'success'
                })
            })
            .catch(err => {
                this.$bvToast.toast('Houve um erro inesperado.', {
                    title: 'Empreendimento',
                    autoHideDelay: 5000,
                    type: 'danger'
                })
            })
        }

        }
      });
    },
    recarregarConfiguracoes(tipo) {
       // this.fetchEnterprise()
    },
    async fetchConfig(type){

        let data = {
          type: type
        }
        
        return serviceConfig.search(data).then();
    },
    fetchEnterprise(){

        if(!this.id){
            return;
        }

        let data = {
            id: this.id
        };

        service
        .read(data)
        .then(resp => {
            setTimeout(() => {
               
                this.enterprise = resp;
                //  $("#summernote").summernote("editor.pasteHTML", this.$data.enterprise.description)
                this.enterprise.ground_floor = this.enterprise.ground_floor == 1;
                this.enterprise.mezzanine = this.enterprise.mezzanine == 1;
                this.enterprise.is_active = this.enterprise.is_active == 1;
                this.enterprise.image = [{path: resp.image}];
                this.enterprise.description = this.enterprise.description
                this.enterprise.image_principal = [{path: resp.image_principal}];
                this.enterprise.configs = resp.configs.map(elem =>{
                    return elem.config_id
                });
            }, 1000)
        })
        .catch(err => {
            console.log(err);
        });
    },
    fetchUauEnterprise(){

   
        serviceUau
        .search()
        .then(resp => {
           this.uauEnterprises = resp.map(elem => elem.DescricaoEmpresa)
           this.uauEnterprises = this.uauEnterprises.filter((element, index) => {
                return this.uauEnterprises.indexOf(element) === index;
           })

           this.uauEnterprises = this.uauEnterprises.map(elem => {
            return {
                value: elem,
                text: elem
            }
           })
           this.uauEnterprises.unshift({
            value: null,
            text: 'Vincule uma unidade'
           })
           
        })
        .catch(err => {
            console.log(err);
        });
    }
  },
  async mounted() {
    this.id = this.$route.params.id;
    this.fetchEnterprise();
    this.fetchUauEnterprise();

    $(document).ready(function() {
    $('#summernote').summernote({
        height: 300,  
        toolbar: [
          ['style', ['style']],
          ['font', ['bold', 'underline', 'clear']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']]
        ]
    });
    });

    var resp = await this.fetchConfig('TYPE');
    this.types = this.types.concat(resp.map(elem => {
        return {
            value: elem.id,
            text: elem.name
        }
    }))

    var resp = await this.fetchConfig('STATUS');
    let status=[]
    resp.forEach((ele) => {
        status[status.length]={value: ele.id, text: ele.name}
    });
    this.status = status

    var resp = await this.fetchConfig('DIFFERENCES');
    this.differences = resp.map(elem => {
        return {
            value: elem.id,
            text: elem.name
        }
    });
    var resp = await this.fetchConfig('COND');
    this.cond = resp.map(elem => {
        return {
            value: elem.id,
            text: elem.name
        }
    });

    var resp = await this.fetchConfig('FINALIDADE');
    this.finalidade = resp.map(elem => {
        return {
            value: elem.id,
            text: elem.name
        }
    });

    var resp = await this.fetchConfig('METRAGEM');
    this.metragem = resp.map(elem => {
        return {
            value: elem.id,
            text: elem.name
        }
    });

        var resp = await this.fetchConfig('QUARTO');
    this.quarto = resp.map(elem => {
        return {
            value: elem.id,
            text: elem.name
        }
    });

        var resp = await this.fetchConfig('SUITE');
    this.suite = resp.map(elem => {
        return {
            value: elem.id,
            text: elem.name
        }
    });

    this.floors = this.floors.concat(new Array(40).fill().map((e,i) => {
        return {value: i + 1, text: i + 1}
    }));

    this.numbers = this.numbers.concat(new Array(10).fill().map((e,i) => {
        return {value: i + 1, text: i + 1}
    }));
      
  },
};
</script>
<style lang="scss">
.active-tab-qsuite{
    border: none !important;
    border-bottom: 2px solid #02b475 !important;
}

.panel-heading {
    background: white;
    border-bottom: 1px solid gray;
}

.note-editable {
    background: white;
}
</style>